var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterShippings',{attrs:{"company":_vm.company},on:{"filtersShipping":_vm.filtersShipping}})],1),_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.shippings},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Envíos")]),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('download')),expression:"permissions('download')"}],attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.exportExcel}},[_vm._v("mdi-download")])],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-container',{staticStyle:{"margin-bottom":"-65px!important"}},[_c('v-select',{staticStyle:{"width":"100px","display":"inline-block","margin-right":"30px","font-size":"14px"},attrs:{"items":_vm.sumavg},model:{value:(_vm.totalType),callback:function ($$v) {_vm.totalType=$$v},expression:"totalType"}}),_c('v-select',{staticStyle:{"width":"100px","display":"inline-block","margin-right":"30px","font-size":"14px"},attrs:{"items":_vm.campos,"item-text":"text","item-value":"value"},model:{value:(_vm.campo),callback:function ($$v) {_vm.campo=$$v},expression:"campo"}}),(_vm.totalType=='sum')?_c('strong',[_vm._v("Total = "+_vm._s(_vm.sumField(_vm.campo)))]):_vm._e(),(_vm.totalType=='avg')?_c('strong',[_vm._v("Promedio = "+_vm._s(_vm.avgField(_vm.campo)))]):_vm._e()],1)]},proxy:true},{key:"item.initial_km",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.initial_km)+" km ")]}},{key:"item.final_km",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.final_km)+" km ")]}},{key:"item.route",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.route)+" km ")]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.weight)+" kg ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissions('editShippings')==true || _vm.permissions('deleteShippings')==true)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editShippings')),expression:"permissions('editShippings')"}],on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('deleteShippings')),expression:"permissions('deleteShippings')"}],on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar ")],1)],1)],1):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, 10))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.slice(0, 10))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de envíos aún ")]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail',{attrs:{"shipping":item.id}})],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e74919","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('create',{attrs:{"company":_vm.company},on:{"closeCreateDialogShipping":_vm.closeCreateDialogShipping}})],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('edit',{attrs:{"shipping":_vm.shipping},on:{"closeDialogEditShipping":_vm.closeDialogEditShipping}})],1),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Seguro que deseas borrar este envío? ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteShipping()}}},[_vm._v(" Eliminar ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }